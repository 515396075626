<template>
<div>
  <b-card-group deck>
    <b-card
    
      title="View All Appointments"
    >
    
      <downloadexcel
	class   = "btn btn-default"
	:data   = "items"
	name    = "Appointment.xls">
Export
</downloadexcel>
     <TableView
  :headers="columns"
  :rows="items"      
  :sort="sort1"
>
  <template v-slot:items="{ row }">
    <td>{{ row.id }}</td>
    <td>{{ row.client }}</td>
    <td>{{ row.description }}</td>               
    <td>{{ row.appointment_Date }}</td>    
    <TablePagination></TablePagination>        
  </template>
  <template v-slot:no-data>
    <span>No data</span>
  </template>
</TableView>
    </b-card>  
  </b-card-group>
</div>
</template>

<script>
import downloadexcel from "vue-json-excel";
import TableView from "@/components/TableView.vue"
import TablePagination from "@/components/TablePagination.vue"
export default {
  components:{
    TableView,
    TablePagination,
    downloadexcel
  },
   data(){
    return{
      columns: [
        {
        key: 'id',
            label: 'ID',
        },
        {
        key: 'name',
        label: 'Client Name',
        },
        {
        key: 'description',
        label: 'Description',
        },
        {
        key: 'apointment_date',
        label: 'Appointment Date',
        }
    
      ], // column data
      items: [
        {
          id: 1,
          client: "Shawna Dubbin",
          description: "Examination of the tooth gum",
          appointment_Date: "2015-07-20"
        },
        {
          id: 2,
          client: "Odette Demageard",
          description: "Vaccination against Covid",          
          appointment_Date: "2019-07-20"
        },
        {
          id: 3,
          client: "Vera Taleworth",
          description: "Preparation on pelvic surgery",
          appointment_Date: "2020-12-30"
        },
        {
          id: 4,
          client: "Lonnie Izkovitz",
          description: "Discussion of Laboratory results",
          appointment_Date: "2015-07-20"
        },
        {
          id: 5,
          client: "Thatcher Stave",
          description: "Vaccination agains Polio",
          appointment_Date: "2018-08-10"
        },
        {
          id: 6,
          client: "Karim Chipping",
          description: "Prescription of drugs",
          appointment_Date: "2020-07-20"
        },
        
      ], // tabular data
      sort1:{
        field: 'full_name',
        order: 'asc'
      },    
      pagination:{
        itemsPerPage: 3,
        visualStyle: 'select'
      }
    }    
  },
  headers: {
  type: Array,
  default() {
    return []
  },
  required: true
},   
rows: {
  type: Array,
  default() {
    return []
  }, 
  required: true
},        
sort: {
  type: Object,
  default() {
    return {}
  }
},
//  page : Number,
// totalPages: Number,
//  paginationOptions: Object,
pagination: {
  type: Object,
  default() {
    return {}
  }
},
cssStyle: {
  type: String,
  default: 'ozn'
}

}
</script>
<style scoped>
.btn.btn-default {
      color: #1313ea;
    background-color: #F3F6F9;
    border-color: #F3F6F9;
    float: right;
}
</style>